.observer-page {
    width: 100vw;
    height: 100vh;
    background-color: white;
    padding: 32px 40px;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    row-gap: 48px;
}

.no-orders.observer {
    position: absolute;
    bottom: 10vh;
    left: 5vw;
}

.observer.no-orders img {
    width: 20vw;
}

.observer.no-orders h4,
.observer.no-orders h3 {
    font-weight: 700;
    margin: 0;
}

.observer.no-orders h4 {
    font-size: 4em;
}

.observer.no-orders h3 {
    font-size: 6em;
    line-height: 5vh;
}

.observer.no-orders .dish {
    margin: 48px 0 64px;
}

.observer.no-orders.light h3 {
    color: black;
}

.observer-item .graphics {
    background-color: black;
    border-radius: 40px;
    font-size: 5em;
    color: white;
    display: flex;
    align-items: center;
    column-gap: 32px;
    padding: 24px;
    font-weight: 700;
    line-height: 16px;
}

.observer-item {
    display: flex;
    width: 100%;
    flex-direction: row;
    column-gap: 32px;
    align-items: center;
}

.observer-item .food-icon svg {
    width: 1em;
    height: 1em;
    padding: 4px;
    box-sizing: border-box;
}

.observer-item .food-icon {
    margin: 0;
    height: 1em;
}

.observer-item .order-title {
    font-size: 5em;
}

.observer-page .stats {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #d9d9d9;
    border-top: 3px solid black;
    display: flex;
    justify-content: center;
    padding: 32px 64px;
    box-sizing: border-box;
    z-index: 999;
}

.observer-page .stats-column {
    display: flex;
    column-gap: 24px;
    align-items: center;
    font-size: 2em;
    font-weight: 700;
}

.observer-page .stats-column h4 {
    font-size: 2.5em;
    margin: 0;
}

.observer-page .stats-column img {
    width: 1.2em;
}

.observer-page .status {
    z-index: 999;
}

.empty-orders {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 90vw;
}

.empty-orders h3 {
    color: black;
    text-align: center;
    font-weight: 600;
    font-size: 4vh;
    line-height: 5vh;
}

.empty-orders img {
    width: 50%;
}

/* Hide cookie consent bar for kitchen display */
div#ppms_cm_popup_overlay,
div#ppms_cm_popup_wrapper {
    display: none !important;
}
