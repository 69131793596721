.summary {
    padding: 16px;
}

.summary .menu-items {
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.summary b,
.summary p {
    margin-bottom: 0;
    margin-top: 0;
}

.summary p {
    margin-left: 16px;
}

.summary .date {
    width: 100%;
    margin-bottom: 8px;
}

.summary .actions {
    display: flex;
    column-gap: 8px;
    width: max-content;
    margin-right: 0;
    margin-left: auto;
    margin-top: 32px;
}
