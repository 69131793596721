.photo {
    text-align: left;
    margin-top: 8px;
    margin-left: 16px;
}

.photo img {
    max-width: 150px;
}

.photo .wrapper {
    display: flex;
    column-gap: 16px;
}

.photo .wrapper .actions {
    flex-direction: column;
    row-gap: 4px;
    justify-content: end;
    width: max-content;
}

.photo .wrapper .actions .btn {
    width: 100%;
    margin-bottom: 8px;
}

.photo label.error {
    color: red;
    margin-top: 8px;
    display: block;
    margin-left: 0;
}
