.add-business input[type='checkbox'] {
    width: max-content;
    margin: 16px 8px 0 0;
}

.add-business .actions {
    position: absolute;
    bottom: 8px;
    right: 16px;
    display: flex;
    column-gap: 8px;
}

.add-business .input-group {
    margin-bottom: 8px;
}
