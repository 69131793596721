.header {
    display: flex;
    column-gap: 32px;
    color: white;
    align-items: center;
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;
    padding-top: 32px;
}

.registry-page {
    max-width: 1200px;
    width: 75%;
    box-sizing: border-box;
    margin: 0 auto;
}

.pagination {
    margin-bottom: 32px;
}

.pagination ul {
    display: flex;
    column-gap: 8px;
    justify-content: center;
    list-style-type: none;
}

.pagination .active {
    font-weight: bolder;
    border: 1px black solid;
}

.pagination li {
    padding: 4px 8px;
}

.pagination li:hover {
    cursor: pointer;
}
