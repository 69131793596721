.modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #f4f4f4;
    padding: 12px 24px;
    border-radius: 6px 6px 0 0;
    box-sizing: border-box;
}

.modal-header h3 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
}

.modal-header img:hover {
    cursor: pointer;
}

.modal-actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 12px 24px;
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    box-sizing: border-box;
    column-gap: 16px;
}

.modal-actions .btn {
    margin-left: 0;
}

.modal-content {
    margin-bottom: 64px;
    overflow-y: auto;
    padding: 12px 24px;
    flex-grow: 1;
}
