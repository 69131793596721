.add-menu-item {
    padding: 16px;
}

.select-registry {
    min-width: 25vw;
    max-width: 75%;
}

.add-menu-item input {
    width: 100%;
    padding: 8px;
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    box-sizing: border-box;
}

.react-datepicker-wrapper {
    margin-top: 8px;
    width: 100%;
    height: 32px;
}

.add-menu-item .weekly {
    margin-top: 8px;
    display: flex;
    justify-content: flex-start;
    column-gap: 8px;
}

.add-menu-item input[type='checkbox'] {
    width: initial;
}

.add-menu-item .actions {
    display: flex;
    column-gap: 8px;
    margin-top: 32px;
    width: max-content;
    margin-right: 0;
    margin-left: auto;
}

.errors,
.success-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.errors .error {
    color: red;
}

.success-wrapper .success {
    color: green;
}

.add-menu-item .flex {
    column-gap: 8px;
}

.select-type {
    width: max-content;
}
