.edit-mode-warning {
    position: fixed;
    bottom: 0;
    width: calc(100% - 96px);
    box-sizing: border-box;
    padding: 16px;
    background-color: #00003c;
    color: white;
    text-align: left;
    display: flex;
    justify-content: space-between;
}

.edit-mode-warning .actions {
    display: flex;
    column-gap: 8px;
}

.edit-mode-warning .actions .btn-white {
    background-color: white;
}

.edit-mode-warning .actions .btn-white:hover {
    color: #2b5abc;
    opacity: 0.5;
}
