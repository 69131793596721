.registry-item-modal {
    min-width: 30vw;
    max-width: 1000px;
    max-height: 80vh;
    overflow: auto;
}

.registry-item-modal .input-group {
    padding: 4px 16px 0;
}

.registry-item-modal .icon {
    margin-left: 16px;
}

.registry-item-modal .title {
    width: 100%;
}

.registry-item-modal .error {
    margin: 4px 32px 24px;
    color: red;
}

.registry-item-modal .item-select:hover {
    cursor: initial;
    background-color: transparent;
}

.registry-item-modal .existing-items {
    margin: 0 32px;
}
