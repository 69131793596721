.profile {
    width: calc(100% - 96px);
}

.profile-content {
    margin: 16px;
    text-align: left;
    /* width: calc(100% - 96px); */
}

.box {
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 8px;
    padding: 16px;
}

.buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.language-button {
    border-radius: 24px;
    border: 1px solid;
    padding: 7px 8px;
    cursor: pointer;
    margin: 0 10px;
}

.selected-language {
    color: #fff;
    background-color: #2b5abc;
}

.logout {
    cursor: pointer;
    border: 1px solid #e01616;
    font-size: 14px;
    line-height: 18px;
    text-align: right;
    padding: 7px 8px;
    color: #e01616;
    border-radius: 4px;
}

.user-options {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.options-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 18px;
}

.options-text {
    margin-bottom: 18px;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

.options-text-switch {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
}

.options-content {
    display: flex;
}

.options-mutable {
    color: #7f7f7f;
}
