.menu-item {
    margin: 16px;
    border-radius: 4px;
    padding: 16px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12),
        0 1px 3px rgba(0, 0, 0, 0.2);
    text-align: left;
}

.menu-item:hover {
    cursor: pointer;
}

.menu-item-main-row,
.menu-item-desc-row,
.menu-item-main {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.menu-item-main-row,
.menu-item-desc-row {
    width: 100%;
    justify-content: space-between;
}

.menu-item-main .food-icon svg {
    transform: scale(1.5);
}

.menu-item-name {
    margin-left: 13px;
    font-size: 18px;
    line-height: 27px;
    text-align: left;
    padding: 4px;
    width: 100%;
}

.menu-item-desc-row {
    margin-top: 18px;
    font-size: 13px;
    line-height: 16px;
    color: #7f7f7f;
}

.menu-item-desc {
    font-size: 13px;
    line-height: 16px;
    text-align: left;
    white-space: pre-wrap;
}

.menu-item-rating-price {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    font-size: 18px;
    line-height: 23px;
}

.menu-item-price {
    width: 64px;
    text-align: right;
}

.closed {
    opacity: 50%;
}

.menu-item-rating {
    width: 70px;
}

.menu-item-remove:hover {
    cursor: pointer;
}

.menu-item .actions .btn.btn-gray {
    padding: 0 8px;
}

.menu-item .actions .btn.btn-gray:hover {
    background: rgba(0, 0, 0, 0.03);
}

.menu-item .actions {
    display: flex;
    column-gap: 8px;
}

.ico-down {
    margin-left: 4px;
}

.menu-item.unpublished {
    background-color: rgba(0, 0, 0, 0.09);
    position: relative;
    padding-bottom: 32px;
}

.menu-item.unpublished small {
    position: absolute;
    right: 8px;
    bottom: 8px;
    font-weight: bolder;
}
