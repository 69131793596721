.food-filter {
    margin: 18px 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px;
}

.food-filter .food-filter-element {
    width: calc(20% - 8px);
}
