.login-box .login-form {
    text-align: left;
    margin: 0 32px;
}

.login-box .login-form h1 {
    margin-top: 24px;
    font-size: 24px;
    line-height: 30px;
}

.login-box form {
    margin: 24px 0 32px;
}

.login-box label {
    display: block;
    font-size: 13px;
    line-height: 16px;
    margin-top: 16px;
    margin-bottom: 4px;
}

.login-box .login-button {
    text-align: center;
    margin-top: 32px;
}

.login-box .login-button:disabled {
    opacity: 0.5;
}

.login-options .forgot-password {
    position: absolute;
    text-align: right;
    width: 100%;
    right: 16px;
    color: #2b5abc;
    font-size: 14px;
    line-height: 18px;
}

.login-options .forgot-password:hover {
    cursor: pointer;
}
