.statistics-table td {
    min-width: 64px;
    max-width: 50vw;
    padding-inline: 8px;
}

.statistics-wrapper .pagination div {
    flex: 1;
}

.text-right {
    text-align: right;
}

.statistics-wrapper .pagination {
    margin: 16px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.statistics-wrapper .orders-per-page {
    display: flex;
    column-gap: 16px;
    align-items: center;
}

.statistics-wrapper .orders-per-page label {
    min-width: max-content;
}

.statistics-wrapper .orders-per-page input {
    width: 48px;
    text-align: center;
}
