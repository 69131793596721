.attendance {
    display: flex;
    column-gap: 4px;
    max-width: 90%;
}

.attendance .attendee {
    height: 4px;
    width: 50px;
    border: 1px groove grey;
}

.green {
    background-color: green;
}

.orange {
    background-color: orange;
}

.red {
    background-color: red;
}
