.add-slot .react-datepicker-wrapper {
    margin-top: 0;
}

.add-slot .input-group {
    margin-top: 16px;
}

.add-slot .slot-actions {
    position: absolute;
    bottom: 16px;
    right: 16px;
    display: flex;
    column-gap: 8px;
}

form.add-slot {
    padding-top: 0;
    height: 65vh;
}

.add-slot .checkbox {
    width: max-content;
    padding: 0;
}

.add-slot .checkbox input {
    width: max-content;
}

.add-slot .repeat-days {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    padding-top: 8px;
}

.add-slot .repeat-days div {
    display: flex;
    column-gap: 8px;
}

.repeat-days .input-group {
    margin-top: 4px;
}

.add-slot .input-group:first-of-type {
    margin-top: 0;
}

.repeat-days .input-group:first-of-type {
    margin-top: 8px;
}

.repeat {
    margin-top: 8px;
}

.switch-label {
    display: flex;
    align-items: center;
    column-gap: 4px;
}

.repeat.switch-label.hint small {
    margin-top: 2px;
}

.switch-label small {
    color: #6c6c6c;
    max-width: 50%;
    margin-top: 6px;
}

.repeat-days .switch-label .input-group {
    margin-top: 4px;
}
