.user-preferences {
    background-color: #00003c;
    color: white;
    display: flex;
    margin-top: 15px;
}

.user-preferences .options-title {
    margin-bottom: 0;
}

.btn-blue {
    background-color: #2b5abc;
    color: white;
}

.btn-blue.btn-enabled:hover {
    background-color: white;
    color: #2b5abc;
}

.btn-disabled {
    opacity: 0.5;
}

.btn-disabled:hover {
    cursor: not-allowed !important;
}
