.filters {
    display: flex;
    column-gap: 8px;
    justify-content: space-between;
    align-items: center;
    margin-left: 16px;
}

.filters .input-group {
    text-align: left;
}

.category .filter {
    display: flex;
    margin-top: 8px;
    column-gap: 16px;
    align-items: center;
}

.registry-page .filters svg:hover {
    cursor: pointer;
}

.cost-center {
    margin-bottom: 8px;
}

.filters .actions {
    display: flex;
    column-gap: 8px;
}

.menu-type-filter {
    display: flex;
    column-gap: 8px;
}

.menu-type-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.menu-type-filter {
    margin-top: 4px;
    width: 110px;
}

.menu-type-filter .switch-container {
    margin-top: 0;
}

.show-all {
    display: flex;
    column-gap: 8px;
    align-items: center;
    height: 32px;
}
