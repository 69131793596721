.week-type-selector {
    position: relative;
}

.week-type-selector .input {
    display: flex;
    padding: 6px 12px;
    background: #e9e9e9;
    border-radius: 16px;
    column-gap: 8px;
    width: 165px;
    justify-content: space-between;
}

.week-type-selector .input:hover {
    cursor: pointer;
}

.week-type-selector .input p {
    margin: 0;
}

.dropdown {
    background: #e9e9e9;
    border-radius: 16px;
    position: absolute;
    width: 189px;
    top: -16px;
}

.week-type-selector .flex {
    column-gap: 8px;
}

.dropdown p:hover {
    cursor: pointer;
}
