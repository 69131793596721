.booking-page table {
    width: 100%;
    background-color: white;
    border: none;
    color: black;
    font-size: 14px;
    margin: 0;
}

.booking-page table.booking-table {
    height: 80%;
}

.booking-page table thead,
.booking-page table tbody th {
    border-bottom: 1px solid #cfcfcf;
    padding: 22.5px 0;
    width: 70px;
}

.booking-page table.booking-table tbody td {
    width: 250px;
    padding: 0;
    border: none;
    box-shadow: 0 0 3px 0 #0000001a;
}

.booking-page table tbody td,
.booking-page table thead th {
    padding: 8px;
}

.booking-page table tbody th {
    background: #f5f7ff;
}

.booking-page table.booking-table th:hover {
    cursor: pointer;
}

.booking-page table.booking-table tbody td .wrapper {
    display: flex;
    text-align: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.booking-page table.booking-table tbody td .wrapper .icon img {
    width: 100%;
}

.booking-page table.booking-table td:hover {
    cursor: pointer;
}

.booking-page table.booking-table td.empty:hover {
    background: linear-gradient(0deg, #eef3fd, #eef3fd),
        linear-gradient(
            0deg,
            rgba(233, 233, 233, 0.57),
            rgba(233, 233, 233, 0.57)
        );
}

.booking-page table.booking-table thead th {
    border: none;
    padding: 22.5px 0;
    font-size: 14px;
    line-height: 14.5px;
    font-weight: 600;
    text-align: center;
}

.booking-page table.booking-table tbody td .wrapper .active {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.booking-page table.booking-table tbody td .wrapper .active p,
.booking-page table.booking-table tbody td .wrapper .active b {
    margin: 0;
}

.booking-page table.booking-table td:has(.wrapper .following) {
    border-top: none;
}

.booking-page table.booking-table td:has(.wrapper .trailing) {
    border-bottom: none;
}

.booking-page table.booking-table tbody td .wrapper .booking-content {
    display: flex;
    column-gap: 8px;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    min-width: 0;
}

.booking-page table.booking-table tbody td .wrapper .booking-content .icon {
    background: linear-gradient(0deg, #d7e1ff, #d7e1ff),
        linear-gradient(0deg, #e9e9e9, #e9e9e9);
    display: flex;
    padding: 8px;
    height: calc(100% - 16px);
}
