.booking-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 32px;
}

.booking-page-content {
    width: 100%;
    max-width: 1440px;
    padding: 32px;
    box-sizing: border-box;
}
