.allergens-modal {
    padding: 8px 32px 16px;
    min-width: 200px;
}

.btn.save-allergens {
    padding: 0 8px;
    width: max-content;
    margin-top: 32px;
}

.allergene input {
    margin-right: 16px;
}
