.user-info {
    padding: 12px 16px;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-info-options {
    display: flex;
}

.user-info-options .logout {
    margin-left: 16px;
    margin-top: 4px;
    height: 18px;
}
