.login-box .header {
    padding: 12px 32px;
    background-color: #00003c;
    text-align: left;
}

.login-box .header img {
    width: 45%;
    min-width: 100px;
}
