.item-select {
    display: flex;
    flex-direction: row;
    column-gap: 0;
    align-items: center;
}

.item-select p {
    margin: 0;
    padding: 8px;
}

.item-select:hover {
    cursor: pointer;
    background-color: rgba(43, 111, 188, 0.24);
}

.item-select .food-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 4px;
}

.item-select span.bold {
    font-weight: 600;
}
