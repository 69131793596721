.toast {
    border: 1px solid #00000021;
    background-color: #d97f7f;
    border-left: 3px solid #b30000;
    position: fixed;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 16px;
    display: flex;
    column-gap: 32px;
    color: white;
    z-index: 999;
    animation: slideUp 0.1s ease-in-out;
}

.toast img:hover {
    cursor: pointer;
}

/* Define the keyframes for the sliding-up animation */
@keyframes slideUp {
    from {
        transform: translate(
            -50%,
            100%
        ); /* Start position, slide up from the bottom */
    }
    to {
        transform: translate(-50%, 0); /* End position, slide up to the top */
    }
}
