.tabs {
    display: flex;
    width: 100%;
    background: #e9e9e9;
    border-bottom: 1px solid #b1b1b1;
    padding-top: 8px;
    position: fixed;
    left: 96px !important;
    top: 0;
    z-index: 999;
}

.tabs .tab {
    padding: 10px 20px;
    display: flex;
    column-gap: 6px;
    align-items: center;
    height: 24px;
    background: none;
    box-sizing: content-box;
}

.tabs .tab:hover {
    cursor: pointer;
}

.tabs .tab.active {
    border-bottom: 3px solid #2d2d2d;
}
