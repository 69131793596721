.kitchen-wrapper {
    text-align: center;
    min-height: 100%;
    width: 100%;
}

.sidebar {
    min-width: 96px;
    max-width: 96px;
}

.btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    min-height: 32px;
    border-radius: 2px;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    margin-right: 0;
    margin-left: auto;
    padding: 8px;
}

.btn:hover {
    cursor: pointer;
}

.btn-gray {
    color: #2b5abc;
    background: rgba(0, 0, 0, 0.07);
}

.btn-gray:hover {
    background-color: #2b5abc;
    color: #fff;
}

.btn-green {
    color: #118330;
    background-color: rgba(17, 131, 48, 0.2);
}

.btn-green:hover {
    background-color: rgba(17, 131, 48, 0.1);
}

.btn-round {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    border-radius: 24px;
    padding: 7px 8px;
    width: max-content;
    margin: 0 8px;
}

.btn-round:hover {
    cursor: pointer;
}

.btn-round-active {
    background-color: #2b5abc;
    color: white;
}

.input-form {
    width: 100%;
    height: 32px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 2px;
    font-size: 14px;
    line-height: 18px;
    padding-left: 8px;
    font-family: 'Source Sans Pro', sans-serif;
}

.input-group label,
.repeat-days {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
    text-align: left;
}

.flex {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled:hover {
    cursor: not-allowed;
}

input:focus {
    outline-color: #2b5abc;
}

.login-box {
    position: absolute;
    margin: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    max-width: 450px;
    min-width: 280px;
    background: #ffffff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12),
        0 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 2px;
}

.forgot-password {
    padding: 16px;
}

.forgot-password h2 {
    text-align: left;
}

.login-button {
    background: #2b5abc;
    border-radius: 2px;
    color: white;
    padding: 10.5px 0;
    border: none;
    width: 100%;
}

.login-button:hover {
    cursor: pointer;
}

.password-wrapper {
    position: relative;
}

.toggle-password {
    position: absolute;
    right: 8px;
    top: 26px;
}

.error-form {
    background: #ffd9d9;
}

.loading-wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #f2f2f2;
    z-index: 1;
}

.uploading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: fit-content;
}

.uploading img {
    width: 50%;
}

.error-toast {
    color: red;
    font-size: 13px;
    margin: 24px 0 0;
    display: flex;
}

.error-toast img {
    margin: 0 8px 0 6px;
}

.menu-title,
.users-title {
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    margin-left: 16px;
    margin-top: 32px;
}

.btn-white {
    background-color: transparent;
    border: 1px solid #2b5abc;
    border-radius: 2px;
    color: #2b5abc;
}

.btn-white:hover {
    background-color: #2b5abc;
    color: white;
    border: 1px solid white;
}

.btn-danger {
    background-color: red;
    color: white;
}

.btn-danger:hover {
    background-color: rgba(255, 0, 0, 0.6);
}

.btn-blue:hover {
    opacity: 0.5;
}

button {
    border: none;
}

/* Override default React modal style */
.ReactModal__Content.ReactModal__Content--after-open {
    overflow: initial !important;
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.line-through {
    text-decoration: line-through;
    width: max-content;
    margin-left: 8px;
}

.app-content {
    display: flex;
    align-items: stretch;
    min-height: 100vh;
}

.navbar-wrapper {
    margin-bottom: 32px;
}

.error {
    color: red;
}

.switch-container {
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-top: 16px;
}

.text-elipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}
