.booking-slot {
    border-radius: 4px;
    padding: 16px;
    background: #fff;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.14), 0 2px 1px rgba(0, 0, 0, 0.12),
        0 1px 3px rgba(0, 0, 0, 0.2);
    margin-bottom: 16px;
    position: relative;
}

.main-line {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.booking-slot .actions {
    position: absolute;
    right: 16px;
    top: 16px;
    display: flex;
    column-gap: 8px;
}
