.edit-menu-item {
    margin: 16px;
}

.edit-menu-item .input-group {
    text-align: left;
    width: 100%;
}

.edit-menu-item .input-group label {
    margin-bottom: 4px;
}

.input-row {
    display: flex;
    column-gap: 16px;
    margin-top: 8px;
    align-items: center;
}

.input-group input {
    width: 100%;
}

.w-75 {
    width: 60%;
}

.price {
    width: 40%;
    display: flex;
    column-gap: 16px;
    justify-content: flex-end;
}

.price .input-group {
    width: 96px;
}

.description {
    width: 100%;
}

.description textarea {
    font-family: inherit;
    font-size: inherit;
    padding: 2px 8px;
    height: inherit;
}

.edit-menu-item hr {
    height: 0.2px;
    background: rgba(0, 0, 0, 0.1);
    margin-top: 16px;
}

.edit-menu-item .actions-row {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    width: max-content;
    column-gap: 8px;
}

small.helper {
    margin-left: 8px;
}

.edit-price {
    width: calc(50% - 8px);
    display: flex;
}

.edit-price .input-group {
    width: 50%;
}

.input-row .category {
    width: calc(50% - 42px);
}

.checkbox {
    padding-left: 16px;
    padding-top: 8px;
    column-gap: 8px;
    display: flex;
}

.wrong-price {
    color: red;
    margin-top: 4px;
    margin-right: 16px;
    margin-left: auto;
    display: block;
    width: max-content;
}
