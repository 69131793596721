.room-selector {
    padding: 4px;
    height: 32px;
    border-radius: 16px;
    background: #00000012;
    display: flex;
}

.room {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    column-gap: 4px;
    border-radius: 14px;
}

.room:hover {
    cursor: pointer;
}

.room.active {
    background-color: white;
}

.room img {
    height: 16px;
    width: 16px;
}

.room p {
    line-height: 20px;
    font-size: 15px;
}
