.calendar-container {
    margin-top: 25px;
}

.calendar-wrapper h3 {
    margin-left: 16px;
}

.calendar-options {
    margin: 8px;
    display: flex;
}

.calendar-options .options-title {
    margin-right: 0;
    margin-left: auto;
    display: flex;
    padding: 5px 16px;
    margin-bottom: 0;
}

.booking-history {
    display: flex;
    align-items: center;
}
