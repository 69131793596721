.food-icon.blue svg path {
    fill: #2b5abc;
}

.food-icon.meat svg path {
    fill: #ff7878;
}

.food-icon.fish svg path {
    fill: #5dabd2;
}

.food-icon.lowcarb svg path {
    fill: #c7c71f;
}

.food-icon.vegi svg path {
    fill: #77c785;
}

.food-icon.salad svg path {
    fill: #118330;
}

.food-icon.soup svg path {
    fill: #489ed6;
}

.food-icon.burger svg path {
    fill: #d4860f;
}

.food-icon.ice_cream svg path {
    fill: #6c6c95;
}

.food-icon.coffee svg path {
    fill: #b46d00;
}

.food-icon.soft svg path {
    fill: #6c6c95;
}

.food-icon.spirits svg path {
    fill: #deda1e;
}

.food-icon.beer svg path {
    fill: #ffab33;
}

.food-icon.gray svg path {
    fill: #f0f0f0;
}

.food-icon.wine svg path {
    fill: #b30000;
}

.food-icon.white svg path {
    fill: white;
}
