.loading {
    padding: 24px;
    height: 100%;
    width: 100%;
    display: flex;
}

.menu-closed,
.menu-closing,
.success-toast,
.error-toast {
    margin: 32px 16px 8px;
    min-height: 32px;
    border-right: 1px solid rgba(0, 0, 0, 0.13);
    border-top: 1px solid rgba(0, 0, 0, 0.13);
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.menu-closed {
    background: #ecf1fe;
    border-left: 2px solid #2b5abc;
}

.menu-closing {
    background: #fff5ec;
    border-left: 2px solid #d4860f;
}

.success-toast {
    background: #ecf8ee;
    border-left: 2px solid #118330;
}

.error-toast {
    background: #ffd9d9;
    border-left: 2px solid #e01616;
}

.menu-closed-img,
.success-toast-img {
    padding-left: 6px;
    padding-right: 8px;
}

.menu-closing-text {
    display: flex;
    width: 100%;
}

.menu-closing-text .diff {
    margin-right: 16px;
    margin-left: auto;
}

.menu-users {
    display: flex;
    margin: 12px;
    flex-wrap: wrap;
}

.btn-round {
    margin: 4px !important;
}

.btn-edit {
    background: rgba(0, 0, 0, 0.07) !important;
    position: relative;
    padding: 18px !important;
    border: none !important;
}

.btn-edit img {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.close-toast {
    margin-right: 8px;
    margin-left: auto;
}

.no-menu,
.wrong-filter {
    position: relative;
    height: 100vh;
    width: 100%;
}

.no-menu .content,
.wrong-filter .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: pre-line;
    line-height: 21px;
    color: #404040;
}

.no-menu .image,
.wrong-filter .image {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.menu-actions {
    display: flex;
    justify-content: space-between;

    .admin-actions,
    .menu-type {
        display: flex;
        padding: 8px 16px;
        align-items: center;
        width: 100%;
        justify-content: flex-end;
    }
}

.menu {
    margin-top: 64px;
}

.menu-groups {
    position: relative;
}
