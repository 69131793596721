.add-allergen {
    width: min-content !important;
    padding: 0 10px;
    font-size: 1.2em !important;
}

.row {
    display: flex;
    column-gap: 16px;
}

.allergens-wrapper {
    padding: 4px 2px;
    height: auto !important;
}
