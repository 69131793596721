.week-title,
.week-total {
    font-weight: 600;
    padding: 0;
}

.five {
    width: calc(100% / 5);
}

.four {
    width: calc(100% / 4);
}

.week-options,
.week-title,
.week-total,
.day-options {
    display: flex;
}

.week-total,
.day-price {
    margin-right: 0;
    margin-left: auto;
}

.week-options {
    margin-bottom: 10px;
}

.day-options .food-icon {
    margin-top: 2px;
    display: flex;
    align-items: center;
}

.day-options .food-icon svg {
    scale: 1.3;
}

.date {
    width: 15px;
    margin-right: 15px;
}

.day-options {
    padding-bottom: 5px;
    flex-wrap: wrap;
}

.day-dishes {
    display: flex;
    column-gap: 8px;
    max-width: 70%;
    flex-wrap: wrap;
}
