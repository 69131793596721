.attendees-wrapper {
    display: flex;
    column-gap: 8px;
    margin-top: 8px;
}

.attendees-wrapper .attendee {
    border: 1px solid black;
    border-radius: 50%;
    aspect-ratio: 1;
    padding: 8px;
}

.attendees-wrapper .attendee:hover {
    cursor: initial;
}
