.status-page {
    width: 100vw;
    background-color: #00002b;
    color: white;
    margin-top: -32px;
    padding-top: 2.5vh;
    min-height: calc(100vh + 8px);
    display: flex;
}

.completed,
.progress {
    width: calc(50% - 24px);
    margin: 0 auto;
    margin-top: 2vh;
    color: #00003c;
}

.progress .items {
    display: flex;
    flex-wrap: wrap;
}

.order-item {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin-bottom: 2.5vh;
}

.completed .order-item {
    width: 100%;
    height: calc(90vh / 6 - 2.5vh);
}

.progress .ordered .order-item {
    width: calc(33% - 1vw);
    height: auto;
    margin: 1vh 0.5vw;
}

.progress .ordered .order-item .order-title {
    margin: 0 auto;
}

.completed img.food-icon {
    margin: auto 8px;
    height: calc((90vh / 6 - 2.5vh) / 1.5);
    width: calc((90vh / 6 - 2.5vh) / 1.5);
}

.progress img.food-icon {
    height: calc((90vh / 8 - 2.5vh) / 1.5);
    width: calc((90vh / 8 - 2.5vh) / 1.5);
    margin: auto 4px;
}

.order-title {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.completed .order-title {
    font-size: calc((90vh / 6 - 2.5vh) / 2);
    line-height: calc((90vh / 6 - 2.5vh) / 1.5);
}

.progress .order-title {
    font-size: calc((90vh / 6 - 2.5vh) / 2);
    line-height: calc((90vh / 6 - 2.5vh) / 1.5);
}

.progress .ordered .order-title {
    font-size: calc((90vh / 6 - 2.5vh) / 2);
    line-height: calc((90vh / 6 - 2.5vh) / 1.5);
}

.status {
    position: fixed;
    bottom: 8px;
    left: 8px;
}

.status-page h3,
.observer-page h3 {
    color: white;
    text-align: left;
    font-weight: 300;
    font-size: 7vh;
    line-height: 7.5vh;
    margin: 0 0 1vh;
}

hr {
    margin: 0 0 1.5vh;
}

.header .logo {
    margin: 0 32px;
}

strong.no-orders {
    color: white;
    font-size: 7vh;
    line-height: 8vh;
}

.title.ordered {
    margin-top: 5vh;
}

.time {
    position: fixed;
    top: 4px;
    right: 8px;
    font-size: 2em;
}
