.users {
    width: calc(75vw - 96px);
    overflow-y: auto;
    margin: 40px auto;
    text-align: left;
    position: relative;
}

.user-item {
    border-radius: 10px;
    padding: 10px 16px;
    background: #fff;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12),
        0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.users-header {
    width: calc(100% - 64px);
    padding: 0 16px;
}

.user-item,
.users-header {
    display: flex;
    flex-wrap: nowrap;
    margin: 16px;
}

.direct-payment,
.action {
    text-align: right;
    width: 20%;
}

.action {
    display: flex;
    justify-content: flex-end;
}

.employee-code {
    width: 20%;
}

.email {
    width: 40%;
}

.user-item .email,
.user-item .employee-code .value {
    padding: 6px 0;
}

input.checkbox {
    height: 24px;
    width: 24px;
    margin: 4px 0;
}

.action .btn {
    margin-left: 8px;
}

.search-users {
    width: 200px;
    position: absolute;
    top: 8px;
    right: 16px;
}

.users .users-title {
    margin-top: 0;
}
