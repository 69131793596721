.food-type {
    cursor: pointer;
}

.food-type-icon-wrapper {
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}

.food-type-value {
    margin-top: 8px;
    font-size: 12px;
    line-height: 15px;
    text-transform: uppercase;
    text-align: center;
}

.food-type-enabled {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 2px;
}

.food-type-icon-wrapper svg {
    transform: scale(1.3);
    padding: 14px;
}

/* .food-type-enabled path {
    fill: #2b5abc;
} */
