.sidebar {
    background: #00003c;
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.14), 2px 0 1px rgba(0, 0, 0, 0.12),
        1px 0 3px rgba(0, 0, 0, 0.2);
}

.profile-wrapper {
    height: 64px;
}

.profile-wrapper.for-initials {
    margin-bottom: 32px;
}

.profile-button {
    margin-top: 32px;
}

.calendar {
    position: fixed;
    left: 48px;
    width: 96px;
    top: max(50vh, 550px);
    transform: translate(-50%, -50%);
    color: #fff;
}

.switch-image.profile {
    width: 32px;
    position: fixed;
    left: 32px;
}

.switch-image.back {
    width: 24px;
}

.hidden {
    display: none;
}

.guest {
    position: fixed;
    bottom: 15px;
    left: 22px;
    color: white;
    font-size: 13px;
    line-height: 17px;
}

.guest-switch {
    margin-top: 10px;
    border: 1px solid rgba(255, 255, 255, 0.45);
}

.weekly .icon img {
    width: 32px;
}

.guest a.link {
    color: white;
    text-decoration: none;
}

.guest .link:hover {
    cursor: pointer;
    text-decoration: underline;
}

.initials {
    background: linear-gradient(0deg, #6c6c6c, #6c6c6c),
        linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.25),
            rgba(255, 255, 255, 0.25)
        );
    border: 1px solid #ffffff40;
    color: white;
    margin: 32px auto;
    border-radius: 50%;
    height: 32px;
    width: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.02em;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;
    position: fixed;
    left: calc(96px / 2);
    transform: translateX(-50%);
}

.signout {
    display: none;
}

.initials:hover .signout {
    display: block;
}

.initials:hover {
    cursor: pointer;
}

.initials:hover .content {
    display: none;
}

.sidemenu {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    position: fixed;
    left: 20px;
}

.sidemenu-item {
    width: 54px;
    aspect-ratio: 1;
    border-radius: 3px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sidemenu .sidemenu-item.active {
    background-color: #ff0000;
}

.sidemenu .sidemenu-item:hover {
    cursor: pointer;
    background-color: #ff0000;
}
