.statistics-page {
    display: block;
    width: 100%;
}

.statistics-page .content {
    max-width: 1200px;
    width: 80%;
    box-sizing: border-box;
    margin: 0 auto;
}

table {
    margin: 16px;
    border-collapse: collapse;
    width: 100%;
    box-shadow: 0 1px 4px 0 #00000033;
}

tbody td,
thead th {
    padding: 4px;
    text-align: left;
}

thead th {
    font-weight: bolder;
}

thead {
    background: linear-gradient(0, #f5f7ff, #f5f7ff);
    height: 60px;
}

tbody tr {
    background: linear-gradient(0, #ffffff, #ffffff);
    height: 60px;
}

.statistics-page .filters {
    justify-content: flex-start;
    margin-top: 32px;
    align-items: flex-end;
    position: relative;
}

.statistics-page .date-selector {
    top: 8px;
}

.statistics-page .no-content {
    margin-top: 32px;
}

.statistics-page .statistics-icon {
    width: 32px;
}

.statistics-page .statistics-icon .food-icon {
    display: block;
    margin: 4px auto 0;
    width: min-content;
    scale: 1.2;
}
