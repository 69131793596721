.number {
    font-size: 24px;
    line-height: 30px;
}

.weekday {
    font-size: 16px;
    line-height: 20px;
}

.day {
    margin-bottom: 30px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.5);
    position: relative;
}

.day:hover {
    color: #fff;
    border-right: 4px solid rgba(255, 255, 255, 0.5);
    transition: all;
}

.today {
    color: lime;
}

.day.selected {
    color: #fff;
    font-weight: 600;
    border-right: 4px solid #ff0000;
}

.button:hover {
    cursor: pointer;
}

.menu-exists {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

.day.selected .menu-exists {
    right: 4px;
}

.button-wrapper {
    height: 32px;
    margin-bottom: 45px;
    margin-top: 45px;
}
