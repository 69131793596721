.menu-description {
    min-width: 25vw;
    max-width: 1000px;
    padding: 36px 0 24px;
}

.menu-description .menu-item-main,
.menu-description .menu-item-desc,
.menu-description .menu-item-rating-price,
.menu-description .allergens,
.menu-description .nutrition-values,
.menu-description .actions {
    margin: 0 32px;
}

.menu-description .menu-item-name {
    font-weight: 600;
}

.menu-description .menu-item-desc {
    margin-top: 14px;
    color: #7f7f7f;
}

.menu-description .menu-item-rating-price {
    margin-top: 16px;
    justify-content: flex-end;
}

.menu-description .menu-item-price {
    color: #7f7f7f;
}

.menu-description .allergens {
    color: #7f7f7f;
    font-size: 13px;
}

.menu-description .allergens .items,
.menu-description .nutrition-values .items {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
}

.menu-description .allergen {
    background: #ffd9d9;
    border: 1px solid rgba(0, 0, 0, 0.13);
    box-sizing: border-box;
    border-radius: 2px;
    margin-right: 12px;
    padding: 2px 4px;
    color: #404040;
}

.menu-description hr {
    border: 1px solid #f2f2f2;
}

.menu-description hr.first {
    margin: 32px 0 24px;
}

.menu-description hr.last {
    margin: 24px 0 32px;
}

.menu-description .nutrition-values {
    margin-top: 16px;
}

.menu-description .nutrition-value {
    margin-right: 24px;
    font-size: 12px;
    color: #404040;
}

.menu-description .nutrition-value img {
    margin-right: 4px;
    height: 16px;
}

.menu-description .nutrition-value span {
    vertical-align: super;
}

.menu-description .close {
    position: absolute;
    top: 8px;
    right: 8px;
}
