.booking-page .actions {
    margin-bottom: 16px;
    margin-right: 0;
    margin-left: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    column-gap: 16px;
}

.booking-page .booking-calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
