.bar-offer h2 {
    font-size: 5em;
}

.bar-offer .menu-item-name {
    font-size: 3em;
    line-height: 1.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bar-offer .menu-item-price {
    font-size: 2.5em;
    width: 150px;
    margin-bottom: 8px;
}

.bar-offer .menu-item-desc {
    font-size: 2em;
    line-height: 1.5em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.bar-offer .food-icon {
    width: 64px;
}

.bar-offer .food-icon svg {
    width: 3em;
    height: 3em;
    padding: 8px;
}
