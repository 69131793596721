.search-placeholder {
    background-color: #00000012;
    border-radius: 3px;
    margin-bottom: 3px;
    height: 32px;
    display: flex;
    width: 32px;
    justify-content: center;
    align-items: center;
}

.search-placeholder:hover {
    cursor: pointer;
}

.filters-group .search-bar {
    position: relative;
    width: 250px;
}

.filters-group .search-bar img {
    position: absolute;
    right: 8px;
    top: 16px;
}

.filters-group .search-bar img:hover {
    cursor: pointer;
}

.filters-group .input-form {
    height: 38px;
}

.filters .filters-group .input-group {
    width: 175px;
}

.filters-group {
    display: flex;
    column-gap: 8px;
    align-items: flex-end;
}
