.month-item {
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.45);
    box-sizing: border-box;
    border-radius: 24px;
    padding: 7px 8px;
    width: max-content;
    margin: 0 8px;
}

.month-item:hover {
    cursor: pointer;
}

.year-separator {
    padding: 9px 5px;
    font-size: 20px;
}

.months {
    display: flex;
    width: 80%;
    flex-wrap: wrap;
}
