.add-guest-modal .guests {
    display: flex;
    column-gap: 8px;
}

.add-guest-modal .guests .guest-chip {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 24px;
    height: 24px;
}

.add-guest-modal .guests .guest-chip:hover {
    cursor: pointer;
}

.add-guest-modal .guests .guest-chip.active {
    background-color: #2b5abc;
    color: white;
}

.add-guest-modal .error {
    margin-top: 8px;
}
