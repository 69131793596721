.login-wrapper .logo {
    margin: 40px auto 32px;
}

.login-wrapper .login-button {
    width: calc(100% - 64px);
    max-width: 300px;
    margin: 0 auto 24px;
}

.login-wrapper .manual-login {
    margin-bottom: 40px;
    color: #2b5abc;
    font-size: 14px;
}

.login-wrapper .manual-login:hover {
    cursor: pointer;
}
