.booking-service .actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0;
}

.booking-service .actions .btn,
.booking-service table .actions {
    margin: 0;
}

.booking-service .service-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
}

.booking-service .service-header .title {
    display: flex;
    align-items: center;
    column-gap: 16px;
}

.booking-service .service-header h2 {
    margin: 0;
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.02em;
    text-align: left;
}

.booking-service .service-header .title .btn {
    height: 32px;
}
