.delete-modal {
    min-width: 25vw;
    max-width: 1000px;
    padding: 24px;
}

.delete-modal .actions {
    display: flex;
    justify-content: flex-end;
    width: max-content;
    column-gap: 16px;
    margin-left: auto;
    margin-right: 0;
}
