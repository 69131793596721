.date-selector {
    position: absolute;
    right: 16px;
    top: -8px;
    display: flex;
    column-gap: 32px;
}

.date-selector img:hover {
    cursor: pointer;
}
