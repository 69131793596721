.chip {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 24px;
    height: 24px;
    column-gap: 8px;
}

.chip button {
    background-color: transparent;
}

.chip button:hover {
    cursor: pointer;
}
