.guests-input {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 10px;
    column-gap: 16px;
}

.guests-input .input-wrapper {
    width: 100%;
}

.guests-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
