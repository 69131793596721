.booking-user {
    width: 80%;
    max-width: 900px;
    margin: 32px auto;
    text-align: left;
}

.booking-user .head {
    position: relative;
}

.booking-user .table {
    margin-top: 64px;
}

.no-slots {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;
}
