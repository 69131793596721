.booking-page .week-selector {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 16px;
}

.booking-page .week-selector .change-week:hover {
    cursor: pointer;
}

.booking-page .week-selector .change-week {
    width: 8px;
}

.booking-page .week-selector h2 {
    width: 275px;
    font-size: 20px;
}

.booking-page .week-selector-wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    align-items: center;
}
