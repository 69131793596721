.home {
    display: flex;
    align-items: stretch;
    width: calc(100vw - 96px);
}

.orders,
.orders-wrapper {
    min-width: 25vw;
    max-width: 464px;
}

.home-content {
    width: 100%;
    overflow-y: auto;
    position: relative;
    display: flex;
    align-items: center;
}

.home-content.brunch {
    width: 100%;
    display: block;
}

.menu {
    width: 80%;
    max-width: 900px;
    margin: 0 auto;
}

.logout.cook {
    position: absolute;
    right: 16px;
    top: 16px;
    width: max-content;
}
